<template>
  <div class="page">
    <div  class="p-4 my-4 text-sm rounded bg-blue-900" role="alert">
      <h2 class="text-white text-lg mb-4">Номер заявки клиента: ТА{{id}}</h2>
    </div>
    <div class="flex justify-center mb-4">
      <div class="text-center">
        <h3 class="text-white text-lg mb-2">Способы оплаты</h3>
        <img class="mx-auto" style="width: 200px" src="@/assets/img/logo/raschet.png" alt="">
        <p>Инструкция по оплате заказа через систему Расчет "ЕРИП"</p>
        <p>Оплата в любом банке:интернет-банкинге,мобильном банке, инфокиоске, банкомате, кассе и т.д.</p>
        <p>Совершить оплату можно с использованием: наличных денежных средств, любых электронных денег, банковских платежных карточек.</p>
      </div>
    </div>

    <h3>
      Для проведения платежа за услуги таможенного представителя необходимо отсканировать QR-код или последовательно
      выполнить шаги по инструкции ниже.
    </h3>
    <div class="lg:flex my-5">
      <div class="lg:w-1/4 sm:w-full">
        <div class="dark:bg-gray-500 w-52 rounded">
          <img src="@/assets/img/qr/qr.png" class="mx-auto">
        </div>

<!--        <p>КОД УСЛУГИ В ЕРИП: XXXXXX</p>-->
<!--        <p>Декларирование <br> или хранение ТС</p>-->
      </div>
      <div class="lg:w-3/4 sm:w-full">
          <ul>
            <li>
              Шаг 1. Выбрать:
              <ul class="px-5">
                <li>
                  Пункт Система Расчет (ЕРИП)
                </li>
                <li>
                  Прочие платежи
                </li>
                <li>
                  Белтаможсервис
                </li>
                <li>
                  Декларирование и/или хранение транспортных средств
                </li>
              </ul>
            </li>
            <li>
              Шаг 2. Для оплаты ввести номер заявки клиента
            </li>
            <li>
              Шаг 3. Проверить корректность информации
            </li>
            <li>
              Шаг 4. Совершите платеж
            </li>
            <li>
              Шаг 5. После успешной оплаты вы получите электронный чек. Информация, указанная в чеке, содержит все данные о проведенной платежной транзакции.
            </li>
            <li>
              Шаг 6. Сохраненную копию чека (.pdf или скриншот) необходимо прикрепить на форме ниже.
            </li>
          </ul>
      </div>

    </div>

    <p class="pb-5 text-blue-500">Если Вы осуществляете платеж в кассе банка, пожалуйста, сообщие кассиру
      о проведении платежа через систему "Расчет" (ЕРИП)</p>
    <p class="pb-5 text-blue-500">
      При оплате Вы подтверждаете сове согласие с условиями договора публичной оферты.
    </p>
    <div>
      <vue-dropzone ref="payments"
                    class="dark:bg-gray-800 dark:hover:border-gray-700 hover:cursor-pointer h-full rounded"
                    id="dropzone3" :options="dropzoneOptions3"
                    @vdropzone-file-added="setPayments"
                    @vdropzone-file-added-manually="setPayments"
                    @vdropzone-removed-file="removePayments"></vue-dropzone>
    </div>
    <button :disabled="loading"
            class="dark:bg-green-400 bg-green-400 mt-4 px-5 py-3 rounded" @click="submit">
      <svg class="spinner float-left mr-2" viewBox="0 0 50 50" v-if="loading">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
      Отправить
    </button>
    <button @click="close" class="mt-4 px-5 py-3 rounded bg-red-600 ml-4">Закрыть</button>
  </div>
</template>
<script>
import axios from "axios";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
  components: {vueDropzone: vue2Dropzone},
  data:() =>({
    id:null,
    loading:false,
    dropzoneOptions3: {
      url: 'https://declarant.by/rest/user/transport-declaration/add',
      autoProcessQueue: false,
      thumbnailWidth: 150,
      maxFilesize: 50000000,
      addRemoveLinks: true,
      maxFiles: 1,
      uploadMultiple: true,
      dictDefaultMessage: 'Копия электронного чека',
      dictMaxFilesExceeded: 'Максимум 1 файл',
      dictRemoveFile: 'Удалить'
    },
    payments:'',
  }),
  created() {
    this.id = this.$route.params.id
  },
  methods:{
    setPayments(file) {
      this.payments = file.name
    },
    removePayments() {
      this.payments = ''
    },
    close(){
      this.$router.push({
        name: 'TransportDeclaration'
      })
    },
    submit(){
      let data = new FormData()

      let payments = this.$refs.payments.getQueuedFiles()
      if(!payments.length){
        return this.$notify({
          title: 'Ошибка',
          text: 'Прикрепите файл',
          type: 'error'
        })
      }
      this.loading = true
      payments.forEach((file, index) => {
        data.append(`payment-${index}`, file)
      })
      axios({
        method: 'post',
        url: `https://declarant.by/rest/user/transport-declaration/attach-payment/${this.id}`,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      }).then(res => {
        this.$notify({
          title: 'Заявка сохранена',
          text: res.data.data,
          type: 'success'
        })
       this.close()
      }).catch(err => {
        this.$notify({
          title: 'Ошибка',
          text: err.response.data.message,
          type: 'error'
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.page {
  @apply my-10  mx-auto relative
  xl:container xl:px-5;
}
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 20px;
  height: 20px;
  position: relative;
}

.spinner .path {
  stroke: rgb(255, 255, 255);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>